(() => {

	/**
	* If website was added to home screen, links open in safari, which is very stupid. 
	* Make sure they open in the app's window.
	* See https://gist.github.com/kylebarrow/1042026
	*/

	/* global window, document */

	if ('standalone' in window.navigator && window.navigator.standalone) {

		console.log('Standalone: You\'re in standalone mode');

		document.addEventListener('click', function(ev) {
			
			let currentNode = ev.target;

			// Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
			while (currentNode.nodeName !== 'A' && currentNode.nodeName !== 'HTML') {
		        currentNode = currentNode.parentNode;
		    }
			
		    console.log('Standalone: Target is %o', currentNode);

			if (!currentNode.hasAttribute('href')) return;

			ev.preventDefault();
			const href = currentNode.getAttribute('href');
			console.log('Standalone: Hijack link %o', href);
			document.location.href = href;
		
		});
	}

})();

